import Meta from "../components/Meta";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { base_url } from "../utils/baseUrl";

export default function Signup() {
  const navigate = useNavigate();
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    mobile: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${base_url}user/register`, formdata);
      if (res.data.role) {    
        toast.success("Registered Sucessfully");
        navigate("/login")
      }
      else{
       toast.info(res.data)
       navigate("/login")
      }
    } catch (error) {
      toast.error("User deatils already exists! Please change email and Mobile");
    }
  };

  return (
    <>
      <Meta title="AFS Deals - Sign-Up With us" />
      <div className="loginwrapper pb-5 pt-2">
        <div className="container">
          <h2 className="mb-2 text-center">Register With us</h2>
          <div className="signup login container mt-2 mt-md-5">
            <div className="left login">
              <form className="form login" >
                <div className="input-block login">
                  <input
                    className="input  login"
                    type="text"
                    onChange={(e) =>
                      setFormdata({ ...formdata, firstname: e.target.value })
                    }
                    required
                  />
                  <label className="login">First Name</label>
                </div>
                <div className="input-block login">
                  <input
                    className="input  login"
                    type="text"
                    onChange={(e) =>
                      setFormdata({ ...formdata, lastname: e.target.value })
                    }
                    required
                  />
                  <label className="login">Last Name</label>
                </div>
                <div className="input-block login">
                  <input
                    className="input  login"
                    type="email"
                    onChange={(e) =>
                      setFormdata({ ...formdata, email: e.target.value })
                    }
                    required
                  />
                  <label className="login">Email</label>
                </div>
                <div className="input-block login">
                  <input
                    className="input  login"
                    type="number"
                    onChange={(e) =>
                      setFormdata({ ...formdata, mobile: e.target.value })
                    }
                    required
                  />
                  <label className="login">Mobile</label>
                </div>
                <div className="input-block login">
                  <input
                    className="input login"
                    type="password"
                    id="pass"
                    required
                    onChange={(e) =>
                      setFormdata({ ...formdata, password: e.target.value })
                    }
                  />
                  <label className="login">Password</label>
                </div>

                <div className="input-block login">
                  <button className="loginbtn"  onClick={(e)=>handleSubmit(e)}>SignUp</button>
                </div>
                <div className="d-flex align-items-center justify-content-around text-black ">                
                    <p className="text-black mb-0 ik">
                      Already registered with us ?
                    </p>
                  <Link
                    to={"/login"}
                    className="btn btn-success text-white text-center"
                  >
                    LogIn
                  </Link>
                </div>
              </form>
            </div>
            <div className="right login"></div>
          </div>
        </div>
      </div>
    </>
  );
}
