import { React, useEffect, useState } from "react";
import CustomInput from "../component/CustomInput";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getColors } from "../features/color/colorSlice";
import {  getsizes } from "../features/Size/SizeSlice";
import {    getQuantitys } from "../features/Quantity/quantitySlice";
import { Select , } from "antd";
import {message} from 'antd'
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { createProducts, resetState } from "../features/products/productSlice";
let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  brand: yup.string().required("Brand is Required"),
  category: yup.string().required("Category is Required"),
  subcategory: yup.string().required("Category is Required"),
  size: yup.string().required("Size is Required"),
  tags: yup.string().required("Tag is Required"),
  color: yup
    .array()
    .min(1, "Pick at least one color")
    .required("Color is Required"),
  quantity: yup.number().required("Quantity is Required"),
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color, setColor] = useState([]);
  const [size, setSize] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [images, setImages] = useState([]);
 
  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(getColors());
    dispatch(getsizes());
    dispatch(getQuantitys());
  }, []);

  const brandState = useSelector((state) => state.brand.brands);
  
  const catState = useSelector((state) => state.pCategory.pCategories);
  const sizeState = useSelector((state) => state.size.sizes);
  const colorState = useSelector((state) => state.color.colors);
  const quantityState = useSelector((state) => state.Quantity.Quantitys);
 
  const imgState = useSelector((state) => state.upload.images);
  const newProduct = useSelector((state) => state.product);
  const { isSuccess, isError, isLoading, createdProduct } = newProduct;
  useEffect(() => {
    if (isSuccess && createdProduct) {
      message.info("Product Added Successfullly!");
    }
    if (isError) {
      message.err("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const coloropt = [];
  colorState.forEach((i) => {
    coloropt.push({
      label: i.title,
      value: i._id,
    });
  });


  const quantityopt = [];
  quantityState.forEach((i) => {
    quantityopt.push({
      label: i.title,
      value: i._id,
    });
  });


  const sizeopt = [];
  sizeState.forEach((i) => {
    sizeopt.push({
      label: i.title,
      value: i._id,
    });
  });


  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

  useEffect(() => {
    formik.values.color = color ? color : " ";
    formik.values.size = size ? size : " ";
    formik.values.images = img;
  }, [color, img,size]);
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
      brand: "",
      category: "",
      tags: "",
      color: "",
      quantity: "",
      images: "",
       size:"",
       Discount:""
    },
    // validationSchema: schema,
    onSubmit: (values) => {

      dispatch(createProducts(values));
    message.info('Product is Added Successfully')
      formik.resetForm();
      setColor(null);
      setSize(null);
        setImages(null);
        imgState(null)
        
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });
  const handleColors = (e) => {
   setColor(e);
   
  };
  const handleSize = (e) => {
    setSize(e);
  
  };
  return (
    <div>
      <h3 className="mb-4 title">Add Product</h3>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-3 flex-column"
        >
          <CustomInput
            type="text"
            label="Enter Product Title"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
            required
          />
          <div className="error text-danger text-danger">
            {formik.touched.title && formik.errors.title}
          </div>
          <div className="">
            <ReactQuill
              theme="snow"
              name="description"
              onChange={formik.handleChange("description")}
              value={formik.values.description}
              required
            />
          </div>
          <div className="error text-danger text-danger">
            {formik.touched.description && formik.errors.description}
          </div>
          <CustomInput
            type="number"
            label="Enter Product Price"
            name="price"
            onChng={formik.handleChange("price")}
            onBlr={formik.handleBlur("price")}
            val={formik.values.price}
            required
          />
          <div className="error text-danger text-danger">
            {formik.touched.price && formik.errors.price}
          </div>
          <CustomInput
            type="number"
            label="Enter Discount in percentage"
            name="price"
            onChng={formik.handleChange("Discount")}
            onBlr={formik.handleBlur("Discount")}
            val={formik.values.Discount}
           
          />
          <select
            name="tags"
            onChange={formik.handleChange("tags")}
            onBlur={formik.handleBlur("tags")}
            value={formik.values.tags}
            className="form-control py-3 mb-3"
            id=""
            required
          >
            <option value="" disabled>
              Select Tag
            </option>
            <option value="featured">Featured</option>
            <option value="popular">Popular</option>
            <option value="special">Special</option>
          </select>
          <div className="error text-danger text-danger">
            {formik.touched.tags && formik.errors.tags}
          </div>
         
          <select
            name="category"
            onChange={formik.handleChange("category")}
            onBlur={formik.handleBlur("category")}
            value={formik.values.category}
            className="form-control py-3 mb-3"
            id=""
            required
          >
            <option value="">Select Category</option>
            {catState.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error text-danger text-danger">
            {formik.touched.category && formik.errors.category}
          </div>
          <select
            name="subcategory"
            onChange={formik.handleChange("subcategory")}
            onBlur={formik.handleBlur("subcategory")}
            value={formik.values.subcategory}
            className="form-control py-3 mb-3"
            id=""
            required
          >
            <option value="">Select Sub Category</option>
            {quantityState.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error text-danger text-danger">
            {formik.touched.subcategory && formik.errors.subcategory}
          </div>
         
          <select
            name="brand"
            onChange={formik.handleChange("brand")}
            onBlur={formik.handleBlur("brand")}
            value={formik.values.brand}
            className="form-control py-3 mb-3"
            id=""
            required
          >
            <option value="">Select Brand</option>
            {brandState.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error text-danger text-danger">
            {formik.touched.brand && formik.errors.brand}
          </div>
         

          <Select
            mode="multiple"
            allowClear
            className="w-100"
            placeholder="Select colors"
            defaultValue={color}
            onChange={(i) => handleColors(i)}
            options={coloropt}
            required
          />
<div className="error text-danger text-danger">
            {formik.touched.color && formik.errors.color}
          </div>




          <Select
            mode="multiple"
            allowClear
            className="w-100"
            placeholder="Select size"
            defaultValue={size}
            onChange={(i) => handleSize(i)}
            options={sizeopt}
            required
          />
  

          <div className="error text-danger text-danger">
            {formik.touched.size && formik.errors.size}
          </div>
          {/* <CustomInput
            type="number"
            label="Enter Product Quantity"
            name="quantity"
            onChng={formik.handleChange("quantity")}
            onBlr={formik.handleBlur("quantity")}
            val={formik.values.quantity}
          />
          <div className="error text-danger text-danger">
            {formik.touched.quantity && formik.errors.quantity}
          </div> */}
          <div className="bg-white border-1 p-5 text-center">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="showimages d-flex flex-wrap gap-3">
            {imgState?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button
                    type="button"
                    onClick={() => dispatch(delImg(i.public_id))}
                    className="btn-close position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  ></button>
                  <img src={i.url} alt="" width={200} height={200} />
                </div>
              );
            })}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct;
