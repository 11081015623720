import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import authService from "./authServices";
import { toast } from "react-toastify";
import  {message} from 'antd'
const getUserfromLocalStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const initialState = {
  user: getUserfromLocalStorage,
  orders: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  token:"",
  role:""
};
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      return await authService.login(userData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    localStorage.removeItem("user");
    toast.info('Logout successfully');
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const registration = createAsyncThunk(
  "user/registration",
  async (user, thunkAPI) => {
    try {
      return await authService.registration(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// address
export const address = createAsyncThunk(
  "user/address",
  async (user, thunkAPI) => {
    try {
      return await authService.address(user);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrders = createAsyncThunk(
  "order/get-orders",
  async (thunkAPI) => {
    try {
      return await authService.getOrders();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }

);
export const getOrder = createAsyncThunk(
  "order/get-order",
  async (thunkAPI) => {
    try {
      return await authService.getOrder();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }

);

export const forget_password_reset=createAsyncThunk(
  "user/forget_password_reset",
  async(data,thunkAPI)=>{
    try{
    
          return authService.forget_password_reset(data)
    }catch(err){
        return thunkAPI.rejectWithValue(err)
    }
  }
)
export const reset_password=createAsyncThunk(
  "user/password_reset",
  async(data,thunkAPI)=>{
    try{
      
          return authService.reset_password(data)
    }catch(err){
        return thunkAPI.rejectWithValue(err)
    }
  }
)
export const OrderStatus=createAsyncThunk(
  "user/order-status",
  async(id,status,thunkAPI)=>{
    try{
      
          return authService.update_order(id,status)
    }catch(err){
        return thunkAPI.rejectWithValue(err)
    }
  }
)


export const resetState = createAction("Reset_all");
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (buildeer) => {
    buildeer
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = "success";
         message.info(action.payload.message)
        state.role=action?.payload?.role
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = "Rejected";
        state.isLoading = false;
        toast.error("Please Confirm your Email And Password")
      })
      .addCase(getOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
        state.message = "success";
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
     
      .addCase(registration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registration.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
        state.message = "success";
        toast.success("register succesfully")
     
      })
      .addCase(registration.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      // Address
      .addCase(address.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(address.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
        state.message = "success";
      })
      .addCase(address.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
       
      }).addCase(forget_password_reset.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(forget_password_reset.fulfilled,(state,action)=>{
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
      message.info("Link Sent Your Email to reset password")
        state.token= action.payload;
        state.message = "success";
      }).addCase(forget_password_reset.rejected,(state,action)=>{
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      }).addCase(reset_password.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(reset_password.fulfilled,(state,action)=>{
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.token= action.payload;
        state.message = "success";
message.info('Your password reset successfully !')
      }).addCase(reset_password.rejected,(state,action)=>{
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      }).addCase(getOrder.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(getOrder.fulfilled,(state,action)=>{
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
       
        state.adminOrders= action.payload;
        state.message = "success";
      }).addCase(getOrder.rejected,(state,action)=>{
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      }).addCase(logout.pending,(state,action)=>{
        state.isLoading = true;
        toast.info('taking time to load please wait...')
      }).addCase(logout.fulfilled,(state,action)=>{
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
       toast.success('Logout Successfully')
      }).addCase(logout.rejected,(state,action)=>{
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
        toast.info('Server Error')
      }).addCase(resetState, () => initialState);
      
  },
});

export default authSlice.reducer;